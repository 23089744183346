import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      listOfRecentActivities: [],
      listOfRecentDocuments: [],
      listOfQuickLinks: [],
      listOfTickets: [],
      listOfOverallStatistics: [],
      chartOptions: { // Chart options for statistics widget
        chart: {
          type: 'column',
          height: 300
        },
        exporting: {
          enabled: false
        },
        title: {
          text: '' // this.$t('moduleInfo')
        },
        credits: {
          enabled: false
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'category',
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            }
          }
        },
        series: [
          {
            name: '',
            colorByPoint: true
          }
        ]
      },
      hoursChartOptions: { // Chart options for Hours Info widget
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 330
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        title: {
          text: '' // this.$t('hoursInfo')
        },
        tooltip: {
          pointFormat: '{point.name}: <b>{point.y}</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y}'
            }
          }
        },
        series: [{
          name: 'Brands',
          colorByPoint: true
        }]
      },
      ticketInfoChartOptions: { // Chart options for tickets info widget
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 330
        },
        exporting: {
          enabled: false
        },
        title: {
          text: '' // this.$t('ticketsInfo')
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{point.name}: <b>{point.y}</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y}'
            }
          }
        },
        series: [{
          name: 'Brands',
          colorByPoint: true
        }]
      },
      ticketsChartOption: { // Chart options for tickets widget
        chart: {
          type: 'column',
          height: 230
        },
        exporting: {
          enabled: false
        },
        // reflow: false,
        title: {
          text: ''
        },
        // plotOptions: { column: { colorByPoint: true } },
        credits: false,
        xAxis: {
          type: 'category'
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          title: {
            text: this.$t('noOfTickets')
          }
        },
        // colors: ['#1976d2'],
        legend: false,
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.y}',
              y: 10,
              style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
              }
            }
          }
        },
        series: [{
          name: '',
          colorByPoint: true
        }]
      },
      userDefinedBarChartOptions: { // Chart options for user defined bar chart widget
        chart: { type: 'column', height: 300 },
        title: {
          text: '' // this.tableDetails.name
        },
        subtitle: false,
        xAxis: {
          categories: [],
          title: false
        },
        yAxis: {
          min: 0,
          title: false
        },
        plotOptions: { column: { dataLabels: { enabled: true } } },
        legend: {
          layout: 'vertical',
          // align: 'right',
          verticalAlign: 'top'
        },
        credits: false,
        series: [],
        exporting: { buttons: false }
      },
      userDefinedPieChartOptions: { // Chart options for user defined pie chart widget
        chart: { type: 'pie', height: 300, options3d: { enabled: true, alpha: 45 } },
        title: {
          text: '' // this.tableDetails.name
        },
        subtitle: false,
        // plotOptions: { pie: { allowPointSelect: true, cursor: 'pointer', dataLabels: { enabled: true, format: '<b>{point.name}</b>: {point.y}' } } },
        plotOptions: { pie: { allowPointSelect: true, dataLabels: { enabled: true, format: '<strong>{point.name}</strong>: {point.percentage:.1f} %' } } },
        series: [{ name: this.$t('groupBy'), data: [] }],
        credits: false,
        exporting: { buttons: false }
      },
      ticketCount: 0,
      pagination: {
        descending: true,
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 5,
        multiSort: false,
        mustSort: true,
        page: 1,
        limit: 5,
        // rowsPerPage: 5,
        search: '',
        skip: 0,
        sortBy: ['name'],
        sortDesc: [false]
      },
      listOfWorkflows: []
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
    ticketTypes () {
      const year = this.$formatter.localeMoment().year()
      const month = (this.$formatter.localeMoment().month() + 1)
      return [{
        url: `dashboard/get_ticket_per_days/${year}/${month}`,
        model: 0
      }, {
        url: `dashboard/get_ticket_per_months/${year}`,
        model: 1
      }, {
        url: 'dashboard/get_ticket_per_years',
        model: 2
      }]
    }
  },
  mounted () {
    this.$eventBus.$on('getTicketChart', (data) => { // Called when changing ticket type in tickets component
      this.getTicketChart(data)
    })
    this.$root.$on('tableWidgetPagination', (data) => {
      this.getTableData(data.model, data.widget)
      this.pagination = data.model
    })
  },
  methods: {
    getTableData (model, widget) {
      const currentWidget = this.$formatter.cloneVariable(this.listOfTotalWidgets)
      const index = currentWidget.selectedcharts.findIndex(x => x.sortid === widget.sortid)
      currentWidget.selectedcharts[index].widgetDetails.loader = true
      this.listOfTotalWidgets = Object.assign({}, this.listOfTotalWidgets, currentWidget)
      this.reInit += 1
      this.$api.execute('post', 'dashboard/get_user_defined_table_dashboards', model).then((response) => {
        const result = response.data.find(x => x.userid === widget.user_id).userdefineddashboardresults[0]
        currentWidget.selectedcharts[index].widgetDetails.listData = this.constructTableData(widget, result.dataset[0].data, widget.fields)
        currentWidget.selectedcharts[index].widgetDetails.loader = false
        this.listOfTotalWidgets = Object.assign({}, this.listOfTotalWidgets, currentWidget)
        this.reInit += 1
      })
    },
    getWidgetData (ids) { // Checks for widgets type and get datas for that widget
      const widgets = [...this.listOfTotalWidgets.selectedcharts]
      ids.forEach(async id => { // Looping through all widgets IDs to check wheather that widget ID is present or not
        const result = widgets.some(x => x.id === id) ? id : null
        switch (result) {
          case 1: // Tickets
            this.getAllTicketsStatus()
            break
          case 2: // Recent Documents
            this.getRecentDocuments()
            break
          case 3: // Recent Activities
            this.getRecentActivity()
            break
          case 9: // My personal document routing
            this.getMyWorkflowData()
            break
          case 5: // User Defined
            this.getUserDefinedWidgetRecords().then(() => this.getPromiseFields(widgets.filter(x => x.id === 5)))
            break
          case 6: // Quick Links
            this.getQuickLinks()
            break
          default:
        }
      })
      const statisticsIds = widgets.filter(x => (x.id === 4 || x.id === 7 || x.id === 8))
      if (statisticsIds && statisticsIds.length) { // Get overall statistics for Statistics, HoursInfo and TicketsInfo
        this.getStatisticsData().then(() => {
          if (statisticsIds.map(x => x.id).includes(4)) this.getStatistics()
          if (statisticsIds.map(x => x.id).includes(7)) this.getHoursInfo()
          if (statisticsIds.map(x => x.id).includes(8)) this.getTicketsInfo()
        })
      }
    },
    getAllTicketsStatus () { // Tickets Widget
      this.$store.dispatch('getTicketStatus').then(() => {
        this.getTicketStatusChart()
        this.getTicketChart()
      })
    },
    getTicketStatusChart () { // Tickets Widget
      const widgets = this.$formatter.cloneVariable(this.getListOfTicketStatus)
      this.$api.execute('get', 'dashboard/get_ticket_widgets')
        .then((response) => {
          if (response && response.data && widgets && widgets.length) {
            const result = response.data
            widgets.forEach(widget => {
              widget.color = this.getColors()
              const getObj = result.find(x => x.id === widget._id)
              if (getObj) {
                widget.count = getObj.value
                this.ticketCount = widget.count
              }
            })
            this.listOfTickets = widgets
          }
        })
        .finally(() => {
          this.stopLoader(1, 'ticketsLoader')
        })
    },
    getTicketChart (widgetIndex) { // Tickets Widget
      const getType = this.ticketTypes.find(x => x.model === (widgetIndex ? widgetIndex.type : 0))
      if (getType) {
        this.$api.execute('get', getType.url)
          .then((response) => {
            const result = response.data
            const chartData = []
            result.forEach(x => {
              switch (getType.model) {
                case 0:
                  chartData.push([`${x.day}. ${x.month}. ${x.year}`, x.value])
                  break
                case 1:
                  chartData.push([`${x.month}. ${x.year}`, x.value])
                  break
                case 2:
                  chartData.push([x.year, x.value])
                  break
              }
            })
            const getOption = this.ticketsChartOption.series[0]
            getOption.data = chartData
            getOption.name = this.$t('noOfTickets')
            this.$set(this.ticketsChartOption.series, 0, getOption)
            if (!widgetIndex) {
              this.listOfTotalWidgets.selectedcharts.forEach((el, index) => {
                if (el.id === 1) {
                  let currentElement = this.$formatter.cloneVariable(this.listOfTotalWidgets.selectedcharts[index])
                  currentElement = {
                    ...currentElement,
                    ticketChartOption: this.$formatter.cloneVariable(this.ticketsChartOption)
                  }
                  this.$set(this.listOfTotalWidgets.selectedcharts, index, this.$formatter.cloneVariable(currentElement))
                }
              })
            } else {
              if (this.listOfTotalWidgets.selectedcharts[widgetIndex.index]) {
                let currentElement = this.$formatter.cloneVariable(this.listOfTotalWidgets.selectedcharts[widgetIndex.index])
                currentElement = {
                  ...currentElement,
                  ticketChartOption: this.ticketsChartOption
                }
                this.$set(this.listOfTotalWidgets.selectedcharts, widgetIndex.index, this.$formatter.cloneVariable(currentElement))
              }
            }
          })
          .finally(() => {
            this.stopLoader(1, 'ticketsChartLoader')
          })
      }
    },
    getRecentDocuments () {
      this.$api.execute('get', 'sharepoint/get_recent_modified_documents?limit=10')
        .then((response) => {
          this.listOfRecentDocuments = response.data
        })
        .finally(() => {
          this.stopLoader(2, 'loader')
        })
    },
    getRecentActivity () {
      this.$api.execute('get', 'recentactivities?limit=10').then((response) => {
        if (response.data) {
          const result = response.data
          const value = []
          result.forEach((x, i) => {
            const sd = this.listOfModules.find(val => val.name === x.module)
            if (sd) {
              value.push({
                color: this.getColors(),
                url: `module/${x.module}/${sd._id}/view/${x.record_id}`,
                listurl: `module/${x.module}/${sd._id}`,
                icon: sd.icon,
                ...x
              })
            }
          })
          this.listOfRecentActivities = value
        }
      }).finally(() => {
        this.stopLoader(3, 'loader')
      })
    },
    getMyWorkflowData () {
      const model = {
        filter: 2,
        userid: this.userDetails._id,
        withactivetask: true
      }
      this.$api.execute('post', 'documentworkflows/get_my_workflows', model)
        .then(({ data }) => {
          this.listOfWorkflows = data.map((workflow) => ({
            ...workflow,
            createdat: (workflow.createdat) ? this.$formatter.formatDate(workflow.createdat, 'DD.MM.YYYYTHH.mm.ss', `${this.userDetails.dateformat} HH:mm`) : ''
          }))
          this.stopLoader(9, 'loader')
        })
    },
    getStatisticsData () {
      return new Promise((resolve, reject) => {
        this.$api.execute('get', 'statistics/get_overall').then(response => {
          this.listOfOverallStatistics = response.data
          resolve()
        })
      })
    },
    getStatistics () {
      const listOfData = this.$formatter.cloneVariable(this.listOfOverallStatistics)
      const chartData = []
      const cat = []
      listOfData.modules.forEach((element, index) => {
        chartData[index] = element.count
        cat[index] = this.$t(element.plural_label)
      })
      const getOption = this.chartOptions.series[0]
      getOption.data = chartData
      getOption.name = this.$t('count')
      this.$set(this.chartOptions.series, 0, getOption)
      this.chartOptions.xAxis.categories = cat
      this.stopLoader(4, 'loader')
    },
    getUserDefinedWidgetRecords () {
      const model = { descending: true, page: 1, rowsPerPage: 5, sortBy: '', search: '', skip: 0, limit: 5 }
      return new Promise((resolve, reject) => {
        this.$api.execute('post', 'dashboard/get_user_defined_dashboards', model).then(response => {
          this.userDefinedWidgetList = response.data
          this.reRender++
          resolve()
        })
      })
    },
    getPromiseFields (widgets) {
      const listOfPromises = []
      const noRepeatedModule = []
      widgets.forEach(el => {
        if (!noRepeatedModule.includes(el.module)) {
          noRepeatedModule.push(el.module)
          listOfPromises.push(this.getModuleFields(el))
        }
      })
      Promise.all(listOfPromises).then(data => {
        this.getWidgetDetails(data) // getWidgetDetails method will be called after all the promise resolved
      })
    },
    getModuleFields (widget) {
      return new Promise((resolve, reject) => {
        const model = {
          moduleid: widget.module,
          exceptfieldtypes: [9, 11, 10, 19],
          isactive_alone: true,
          include_default_fields: true
        }
        this.$api.execute('post', 'modulefields/filter', model).then(response => {
          resolve({ module: widget.module, fields: response.data })
        })
      })
    },
    getWidgetDetails (moduleFields) {
      if (moduleFields && moduleFields.length) {
        if (this.listOfTotalWidgets && (this.listOfTotalWidgets.selectedcharts && this.listOfTotalWidgets.selectedcharts.length)) {
          this.listOfTotalWidgets.selectedcharts = this.constructUserDefinedWidget(this.listOfTotalWidgets.selectedcharts, moduleFields)
        }
        if (this.dashboardGroup && this.dashboardGroup.selectedcharts && this.dashboardGroup.selectedcharts.length) {
          this.dashboardGroup.selectedcharts = this.constructUserDefinedWidget(this.dashboardGroup.selectedcharts, moduleFields)
        }
      }
      this.stopLoader(5, 'loader')
    },
    constructUserDefinedWidget (charts, moduleFields, fromListPage) {
      charts.forEach(widget => {
        if (widget.id === 5) {
          const widgetDetails = {}
          widgetDetails.sortid = widget.sortid
          const widgets = this.userDefinedWidgetList.filter(el => (el && el.userid === widget.user_id))
          let result = null
          widgets.forEach(data => {
            if (data.userdefineddashboardresults && data.userdefineddashboardresults.find(x => x.dataformat === widget.dataformat && x.sortid === widget.sortid)) {
              result = data.userdefineddashboardresults.find(x => x.dataformat === widget.dataformat && x.sortid === widget.sortid)
            }
          })
          if (result) {
            widgetDetails.fields = this.$formatter.cloneVariable(moduleFields.find(x => x.module === widget.module).fields)
            widgetDetails.module = widget.module
            widgetDetails.dataformat = result.dataformat
            widgetDetails.databaseindex = widget.databaseindex
            widgetDetails.groupby = widget.groupby
            widgetDetails.user_id = widget.user_id
            widgetDetails.visiblefields = widget.visiblefields
            widgetDetails.loader = false
            switch (result.dataformat) {
              case 1: { // Bar Chart widget
                widgetDetails.barChartOptions = this.$formatter.cloneVariable(this.userDefinedBarChartOptions)
                const data = { name: 'No Name', data: [] }
                widgetDetails.barChartOptions.series.push(data)
                result.dataset.forEach((x) => {
                  if (this.$moment(x.name, 'DD.MM.YYYY').isValid()) x.name = this.$formatter.formatDate(x.name, 'DD.MM.YYYY', this.userDetails.dateformat)
                  // x.name = x.type === 6 ? this.$formatter.fromUtcToLocal(x.name, 'DD.MM.YYYYTHH:mm:ss', this.userDetails.dateformat) : x.name
                  widgetDetails.barChartOptions.series[0].data.push(x.count)
                  widgetDetails.barChartOptions.xAxis.categories.push(x.name ? this.$t(`${x.name}`) : 'No Records')
                })
                widgetDetails.fields.forEach(element => {
                  if (widget.groupby === element._id) widgetDetails.barChartOptions.series[0].name = this.$t(`${element.label}`)
                })
                widget.widgetDetails = widgetDetails
                break
              }
              case 2: { // Pie Chart widget
                widgetDetails.pieChartOptions = this.$formatter.cloneVariable(this.userDefinedPieChartOptions)
                result.dataset.forEach((x) => {
                  if (this.$moment(x.name, 'DD.MM.YYYY').isValid()) x.name = this.$formatter.formatDate(x.name, 'DD.MM.YYYY', this.userDetails.dateformat)
                  // x.name = x.type === 6 ? this.$formatter.fromUtcToLocal(x.name, 'DD.MM.YYYYTHH:mm:ss', this.userDetails.dateformat) : x.name
                  widgetDetails.pieChartOptions.series[0].data.push([x.name || x._id || '(No Name)', x.count])
                })
                widgetDetails.fields.forEach(element => {
                  if (widget.groupby === element._id) widgetDetails.pieChartOptions.series[0].name = this.$t(`${element.label}`)
                })
                widget.widgetDetails = widgetDetails
                break
              }
              case 3: { // Table widget
                const headerArray = []
                widgetDetails.fields = this.sortFieldsByVisibleFields(widget.visiblefields, widgetDetails.fields)
                widgetDetails.fields.forEach((element) => {
                  if (widget.visiblefields && ((element && element._id && element.type) && widget.visiblefields.includes(element._id)) && element.type !== 10) {
                    headerArray.push({ text: this.$t(`${element.label}`), value: `data.${element.name}` })
                  }
                })
                widgetDetails.headers = headerArray
                widgetDetails.listData = this.constructTableData(widget, (result.dataset && result.dataset[0] && result.dataset[0].data) ? result.dataset[0].data : [], widgetDetails.fields, fromListPage)
                widgetDetails.total = result.dataset[0].total
                widget.widgetDetails = widgetDetails
                break
              }
            }
          }
        }
      })
      return charts
    },
    sortFieldsByVisibleFields (visiblefields, fields) {
      let result = []
      visiblefields.forEach((field) => {
        const fieldItem = fields.find((x) => x._id === field)
        if (fieldItem) result.push(fieldItem)
      })
      result = [...result, ...fields.filter((x) => !visiblefields.includes(x._id))]
      // update name field position
      const nameFieldIndex = result.findIndex((x) => x.name === 'name')
      const nameField = result[nameFieldIndex]
      if (nameFieldIndex <= 0) return result
      result.splice(nameFieldIndex, 1)
      result.unshift(nameField)
      return result
    },
    constructTableData (widget, list, fields, fromListPage) {
      const listData = (list && Array.isArray(list)) ? this.$formatter.cloneVariable(list) : []
      const module = this.listOfModules.find(x => x._id === widget.module)
      listData.map(item => {
        if (module) item.url = (fromListPage) ? `${module._id}/view` : `module/${module.name}/${module._id}/view`
        for (const i in item.data) {
          const result = fields.find(x => x.name === i)
          if (result && item.data[i] && (typeof (item.data[i]) === 'object') && item.data[i].type) {
            switch (Number(item.data[i].type)) {
              case 3: // Checkbox
                item.data[i] = item.data[i].value ? this.$t('yes') : this.$t('no')
                break
              case 4: // Radio
                var radioDefaultValue = result && result.default_value ? JSON.parse(result.default_value) : []
                item.data[i] = (item.data[i].value && radioDefaultValue.options.find(x => x.value === item.data[i].value)) ? radioDefaultValue.options.find(x => x.value === item.data[i].value).label : '-'
                break
              case 5: // Select
                var selectDefaultValue = result && result.default_value ? JSON.parse(result.default_value) : []
                if (selectDefaultValue && selectDefaultValue.is_multiselect) {
                  if (selectDefaultValue.options && selectDefaultValue.options.length) item.data[i] = (item.data[i].value && item.data[i].value.length) ? (selectDefaultValue.options.map(x => item.data[i].value.includes(x.value) ? x.label : null).filter(a => a != null)).join() : '-'
                  else if (item.data[i].value && typeof item.data[i].value[0] === 'object') {
                    const val = item.data[i].value.map(a => a.data.name)
                    item.data[i] = val.length ? val.join() : '-'
                  } else item.data[i] = (item.data[i].value && item.data[i].value.length) ? item.data[i].value.join(', ') : '-'
                } else {
                  if (selectDefaultValue.options && selectDefaultValue.options.length) {
                    const value = selectDefaultValue.options.find(x => x.value === item.data[i].value)
                    item.data[i] = (value && value.label) ? value.label : '-'
                  } else if (item.data[i].value && Array.isArray(item.data[i].value)) item.data[i] = item.data[i].value.length ? item.data[i].value[0].data.name : '-'
                  else item.data[i] = item.data[i].value ? item.data[i].value : '-'
                }
                break
              case 6: // Date
                item.data[i] = item.data[i].value ? this.$formatter.formatDate(item.data[i].value, '', this.userDetails.dateformat) : '-'
                break
              case 15: // User
                var userDefaultValue = result && result.default_value ? JSON.parse(result.default_value) : []
                if (userDefaultValue && userDefaultValue.is_multiselect) item.data[i] = (item.data[i].value && item.data[i].value.length) ? item.data[i].value.map(x => x.firstname + ' ' + (x.lastname ? x.lastname : '')).join() : '-'
                else item.data[i] = (item.data[i].value && item.data[i].value.length) ? item.data[i].value[0].firstname + ' ' + (item.data[i].value[0].lastname ? item.data[i].value[0].lastname : '') : '-'
                break
              case 16: // DateTime
                item.data[i] = item.data[i].value ? this.$formatter.formatDate(item.data[i].value, '', `${this.userDetails.dateformat} HH:mm`) : '-'
                break
              default:
                item.data[i] = item.data[i].value ? item.data[i].value : '-'
            }
          }
        }
      })
      return listData
    },
    getQuickLinks () {
      this.$api.execute('get', 'quicklinks/get_my_quicklinks')
        .then((response) => {
          if (response.data) {
            const result = response.data
            const value = []
            result.forEach((x, i) => {
              value.push({
                color: this.getColors(),
                url: x.link,
                ...x
              })
            })
            this.listOfQuickLinks = value
          }
        })
        .finally(() => {
          this.stopLoader(6, 'loader')
        })
    },
    getHoursInfo () {
      const listOfData = this.$formatter.cloneVariable(this.listOfOverallStatistics)
      const hoursChartData = [
        { name: this.$t('totalHourEntries'), y: listOfData.totalhourentries },
        { name: this.$t('totalHours'), y: listOfData.totalhours },
        { name: this.$t('totalApprovedHours'), y: listOfData.totalapprovedhours },
        { name: this.$t('totalNotApprovedHours'), y: listOfData.totalnotapprovedhours },
        { name: this.$t('totalHourBank'), y: listOfData.totalhoursbank }]
      const getHoursChartOption = this.hoursChartOptions.series[0]
      getHoursChartOption.data = hoursChartData
      getHoursChartOption.name = this.$t('hours')
      this.$set(this.hoursChartOptions.series, 0, getHoursChartOption)
      this.stopLoader(7, 'loader')
    },
    getTicketsInfo () {
      const listOfData = this.$formatter.cloneVariable(this.listOfOverallStatistics)
      const ticketChartData = [
        { name: this.$t('totalTickets'), y: listOfData.totaltickets, selected: true },
        { name: this.$t('openTickets'), y: listOfData.opentickets },
        { name: this.$t('closedTickets'), y: listOfData.closedtickets }]
      const getTicketchartOption = this.ticketInfoChartOptions.series[0]
      getTicketchartOption.data = ticketChartData
      getTicketchartOption.name = this.$t('tickets')
      this.$set(this.ticketInfoChartOptions.series, 0, getTicketchartOption)
      this.stopLoader(8, 'loader')
    },
    getColors () { // Random color generator
      var color = '#'
      var constters = '0123456789ABCDEF'
      for (var i = 0; i < 6; i++) {
        color += constters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    stopLoader (id, loader) {
      this.listOfTotalWidgets.selectedcharts.map(widget => {
        if (widget.id === id) widget.widgetDetails[loader] = false
        return widget
      })
      this.reInit += 1
    }
  },
  destroyed () {
    this.$eventBus.$off('getTicketChart')
    this.$root.$off('tableWidgetPagination')
  }
}
